<template>
  <iframe :src="url" />
</template>

<script>
export default {
  name: 'OfficeViewer',
  props: {
    src: {
      type: String,
      required: true
    }
  },

  computed: {
    url () {
      return `https://docs.google.com/viewer?url=${this.src}&embedded=true`
    }
  }
}
</script>
